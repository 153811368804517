<template>
  <div class="pad-15-hor pad-15-ver search-parent">
    <div class="search-bar">
      <t-input
        class="flex-grow"
        @input="searchText()"
        v-model="search"
        type="text"
        placeholder="Search employees..."
      ></t-input>
      <span class="search-icon">
        <i class="fas fa-search"></i>
      </span>
    </div>
    <div class="container-fluid mt-3" v-if="search">
      <div class="row scroll">
        <div class="col-md-6 pad-15-ver" v-for="employee in employees" :key="employee.id">
          <div class="card-inner cursor-pointer hover:underline" :class="{ 'text-red-500 disabled': employee.scheduled }"
            @click="employee.scheduled || selectUser(employee)">
            <div class="border-b border-gray-200">
              <span class="w-1/2 text-sm py-2">{{ employee.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, lowerCase, sortBy } from "lodash";

export default {
  name: "SearchBar",
  data() {
    return {
      employees: [],
      search: "",
    };
  },
  props: {
    users: {
      type: Array,
      default() {
        return { users: [] };
      },
    },
  },
  methods: {
    searchText() {
      const usersList = this.users.filter(user => {
        if (lowerCase(get(user, 'name')).includes(lowerCase(this.search))
            || lowerCase(get(user, 'first_name')).includes(lowerCase(this.search))
            || lowerCase(get(user, 'last_name')).includes(lowerCase(this.search))
          ) {
          return user;
        }
      });

      this.employees = sortBy(usersList, user => lowerCase(get(user, 'name')));
    },
    selectUser(employee) {
      this.$emit('clicked', employee);
    },
  },
}
</script>
<style scoped lang="scss">
.search-parent {
  flex-flow: row wrap;
  justify-content: space-between;
}

.card-inner {
  position: relative;
  overflow: hidden;
  padding: 3px;
}

.scroll {
  overflow-y: auto;
  max-height: 150px;
}

.search-bar {
  position: relative;
}

.search-bar input {
  padding-left: 35px;
}

.search-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
