<template>
  <div>
    <div class="w-fit" @mouseover="hover" @mouseleave="leave">
      <slot name="hoverable"></slot>
    </div>
    <div v-if="show" class="border-1 border-gray-300 popover-content absolute -mt-24 ml-14 flex items-start py-8">
      <div class="transition duration-150 ease-in-out ml-10 w-10/12 md:w-2/5">
          <div class="w-full bg-white rounded shadow-2xl">
              <div class="relative bg-gray-200 rounded-t py-2 px-4 xl:px-8">
                 <svg class="hidden md:block absolute -ml-5 -mb-10 left-0 bottom-1" width="30px" height="30px" viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Tooltips-" transform="translate(-874.000000, -1029.000000)" fill="#FFFFFF">
                        <g id="Group-3-Copy-16" transform="translate(850.000000, 975.000000)">
                            <g id="Group-2" transform="translate(24.000000, 0.000000)">
                                <polygon id="Triangle" transform="translate(4.500000, 62.000000) rotate(-90.000000) translate(-4.500000, -62.000000) " points="4.5 57.5 12.5 66.5 -3.5 66.5"></polygon>
                            </g>
                        </g>
                      </g>
                    </g>
                </svg>
                <svg class="absolute top-0 -mt-5 block md:hidden" xmlns="http://www.w3.org/2000/svg" width="26" height="23" viewBox="0 0 26 23" fill="none">
                  <path id="Polygon 2" d="M13 0L25.9904 22.5H0.00961876L13 0Z" fill="#e5e7eb" />
                </svg>
                <p  v-if="title"
                  class="m-0 text-base text-gray-800 font-normal leading-normal tracking-normal">
                  {{ title }}
                </p>
              </div>
              <div class="w-full h-full px-4 xl:px-8 py-5">
                <div v-if="loading" class="flex justify-center items-center">
                  <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-400"></div>
                </div>
                <slot v-else name="content"></slot>
              </div>
          </div>
      </div>
      </div>
  </div>
</template>

<script>
  export default {
    name: "HoverPopover",
    props: {
      title: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      show: false
    }),
    methods: {
      hover() {
        this.show = true;
        this.$emit('hover');
      },
      leave() {
        this.show = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
.popover-content {
  width: 600px;
}
</style>
