<template>
  <div>
    <div class="tooltip">
      <slot name="hoverable"></slot>
      <span class="tooltiptext">
        <div v-if="title" class="title">
          {{ title }}
        </div>
        <slot name="content"></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolTip",
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 230px;
  padding: 0 20px 20px 20px;
  border: 1px solid rgb(187, 187, 187);
  background-color: rgb(255, 255, 255);
  color: black;
  text-align: center;
  border-radius: 6px;
  top: -5px;
  left: 105%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 10%;
  right: 100%;
  margin-top: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent rgb(187, 187, 187) transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.title {
  padding: 20px 20px 10px 10px;
  font-weight: 600;
  text-align: left;
}
</style>
